import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { RewardType } from 'models';
import { apiGamification } from 'modules';

import UserContext from '../UserProvider/User.context';
import CampaignContext from './Campaign.context';

import { useCallbackAsync } from 'hooks';

const CampaignProvider: FC<PropsWithChildren<any>> = (props) => {
  const { children } = props;

  const { organisationId } = useParams() as {
    organisationId: string;
  };

  const { communityId } = useContext(UserContext);

  const [rewards, setRewards] = useState<RewardType[]>();

  const [getRewards] = useCallbackAsync({
    spinnerParent: '#rewards_fieldset',
    showSpinner: true,
    deps: [communityId],
    callback: async () => {
      const {
        data: { rewards },
      } = await apiGamification.getRewardsPaginated(organisationId, {});

      setRewards(rewards);
    },
  });

  useEffect(() => {
    getRewards();
  }, [getRewards]);

  return (
    <CampaignContext.Provider
      value={{
        rewards,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

export default CampaignProvider;
