import { theme } from '@faxi/web-component-library';
import { pxToRem } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  &.calendar-container {
    section {
      padding: 0;
    }
  }

  .calendar-container__input {
    .input__container__placeholder {
      transition: unset;
    }
  }

  .wcl-calendar {
    border: unset;
    width: ${pxToRem(theme.sizes.SIZE_320)};
  }
`;

export const PortalContainer = css`
  .wcl-calendar {
    padding: ${theme.sizes.SIZE_12};
    max-width: ${pxToRem(theme.sizes.SIZE_304)};
  }
`;
