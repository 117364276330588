import Dashboard from './Dashboard';
import Messages from './Messages';
import People from './People';
import Reports from './Reports';
import Interstitial from './Interstitial';
import Activate from './Activate';
import Map from './Map';
import NotFound from './NotFound';
import AccountDeletion from './AccountDeletion';
import Survey from './Survey';

export default {
  Dashboard,
  Messages,
  Map,
  People,
  Reports,
  Interstitial,
  Activate,
  NotFound,
  AccountDeletion,
  Survey,
};
