import { useCallback } from 'react';

import { useMsal } from '@azure/msal-react';

import { SocialButton } from 'components';
import { loginRequest } from 'config/microsoftAuthConfig';

const Microsoft = () => {
  const { instance } = useMsal();

  const handleLoginPopup = useCallback(() => {
    instance
      .loginRedirect({
        ...loginRequest,
        redirectUri: `${window.location.origin}/login-redirection`,
        prompt: 'select_account',
      })
      .catch((error) => console.error(error));
  }, [instance]);

  return <SocialButton provider="microsoft" onClick={handleLoginPopup} />;
};

export default Microsoft;
