import { elevate_l, focusStyles, theme } from '@faxi/web-component-library';
import {
  flex,
  flexGap,
  fontSize,
  laptop,
  marginChildren,
  mobile,
  phablet,
  position,
  pxToRem,
  size,
  tablet,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${flex('column')};

  max-width: ${pxToRem(theme.sizes.SIZE_928)};

  ${phablet(
    css`
      width: 100%;
    `
  )};

  form {
    ${marginChildren(`0 0 ${theme.sizes.SIZE_32} 0`)};

    ${laptop(css`
      max-width: 100%;
    `)}

    fieldset {
      border: unset;
      padding: unset;

      legend {
        ${flex('row', 'center', 'center')};
        ${marginChildren(`0 ${theme.sizes.SIZE_8} 0 0`)};
        ${fontSize(theme.fontSizes.FONT_18, '25px')};
        margin-bottom: ${theme.fontSizes.FONT_12};
        padding: unset;
      }

      .textarea__container {
        width: 100%;
        max-width: ${pxToRem(theme.sizes.SIZE_600)};
      }

      .fields {
        ${marginChildren(`0 0 ${theme.sizes.SIZE_32} 0`)};
      }
    }
  }

  .kinto-select-field {
    max-width: ${pxToRem(theme.sizes.SIZE_288)};
  }

  .create-new-campaign {
    &__campaign-type {
      color: var(--SHADE_1_2);
    }

    &__tooltip {
      ${focusStyles};

      margin-left: ${theme.sizes.SIZE_8};

      .wcl-icon {
        ${size(theme.sizes.SIZE_16)};

        svg {
          ${size('100%')};
        }
      }
    }

    &__header {
      ${flex('row', 'space-between', 'baseline')};

      > h1 {
        ${fontSize(theme.fontSizes.FONT_24, '34px')};
        font-weight: 400;
        color: var(--SECONDARY_1_1);
        margin-bottom: ${theme.sizes.SIZE_32};
      }

      &__close-btn {
        svg {
          margin-left: ${theme.sizes.SIZE_12};
        }
      }
    }

    &__name {
      width: ${pxToRem(theme.sizes.SIZE_304)};
    }

    &__rewards {
      ${marginChildren(`0 0 ${theme.sizes.SIZE_16} 0`)};

      &__add-reward {
        ${flex('row', 'flex-start', 'flex-start')};
        ${elevate_l};

        position: relative;
        gap: ${theme.sizes.SIZE_16};
        margin-top: ${theme.sizes.SIZE_16};
        border-radius: ${theme.sizes.SIZE_8};
        padding: ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_24};

        @media (max-width: ${theme.breakAtMaxWidth}px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &__view-details {
          margin-top: auto;

          &--voucher {
            margin-top: ${theme.sizes.SIZE_16};
          }
        }

        &__reward-name {
          ${fontSize(theme.fontSizes.FONT_14)};

          font-weight: 600;
          color: var(--SHADE_1_1);
          width: ${pxToRem(theme.sizes.SIZE_152)};
          margin-top: ${theme.sizes.SIZE_40};
          transform: translateY(-50%);

          @media (max-width: ${theme.breakAtMaxWidth}px) {
            flex: unset;
          }
        }

        > .input {
          flex: 0 0 ${pxToRem(theme.sizes.SIZE_160)};

          @media (max-width: ${theme.breakAtMaxWidth}px) {
            flex: unset;
          }
        }

        > .kinto-select-field {
          flex: 0 0 ${pxToRem(theme.sizes.SIZE_160)};

          @media (max-width: ${theme.breakAtMaxWidth}px) {
            flex: unset;
          }
        }

        .delete-reward {
          ${size(theme.sizes.SIZE_32)};

          min-height: unset;
          margin-left: auto;

          margin-top: ${theme.sizes.SIZE_40};
          transform: translateY(-50%);

          @media (max-width: ${theme.breakAtMaxWidth}px) {
            margin-left: unset;
            ${position(
              'absolute',
              `top ${pxToRem(theme.sizes.SIZE_8)} right ${pxToRem(
                theme.sizes.SIZE_8
              )}`
            )}
          }
        }
      }

      &__reward-number {
        ${elevate_l};
        padding: ${theme.sizes.SIZE_16};
        border-radius: ${theme.sizes.SIZE_8};
        margin-top: ${theme.sizes.SIZE_20};

        &__title {
          ${flex('row', 'space-between', 'flex-start')}
          margin-bottom: ${theme.sizes.SIZE_32};

          .reward-name {
            color: var(--SHADE_1_1);
            font-weight: 600;
            font-size: ${theme.fontSizes.FONT_14};
          }

          .delete-reward {
            height: fit-content;
            min-height: unset;
          }
        }

        &__count {
          display: flex;

          &__input {
            max-width: ${pxToRem('100px')};
          }

          > span {
            margin-top: ${theme.sizes.SIZE_46};
            transform: translateY(-50%);
            color: var(--SHADE_1_2);
            font-size: ${theme.fontSizes.FONT_14};
            height: fit-content;
            margin-left: ${theme.sizes.SIZE_4};
            text-transform: lowercase;
          }
        }

        &__radio {
          > label {
            min-height: ${pxToRem(theme.sizes.SIZE_20)};

            &:first-of-type {
              align-items: flex-start;

              > svg {
                margin-top: ${theme.sizes.SIZE_46};
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }

    &__empty-rewards {
      ${marginChildren(`0 0 ${theme.sizes.SIZE_16} 0`)};
      max-width: ${pxToRem(theme.sizes.SIZE_600)};

      &__label {
        ${flex('row')};
        ${marginChildren(`0 ${theme.sizes.SIZE_8} 0 0`)};
        ${fontSize(theme.fontSizes.FONT_14)};
        color: var(--SHADE_1_2);
      }
    }

    &__actions {
      ${flex('row', 'space-between', 'flex-start')};
      gap: ${theme.sizes.SIZE_24};

      ${tablet(css`
        flex-direction: column;
      `)};

      &__regular,
      &__draft {
        ${flex('row', 'center', 'center')};
        gap: ${theme.sizes.SIZE_24};

        svg {
          ${size('18px')};
        }
      }
    }

    &__section {
      ${flex('row', 'space-between')};

      background-color: var(--BACKGROUND_1_1);
      padding: ${theme.sizes.SIZE_16};
      border-radius: ${theme.sizes.SIZE_8};

      &__fieldset {
        flex: 1;
        margin: unset;

        &__field-container {
          ${flex('row', 'flex-start', 'center')};
          padding: ${theme.sizes.SIZE_8} 0;
          gap: ${theme.sizes.SIZE_12};

          .wcl-divider {
            width: 100%;
            margin: ${theme.sizes.SIZE_8} 0;
            background-color: var(--SHADE_1_1);
          }

          &--column {
            ${flex('column', 'center', 'flex-start')};
          }
        }
      }

      &__rewards {
        ${flex('column')};

        > div:first-of-type {
          ${flex()};
          gap: ${theme.sizes.SIZE_16};

          #rewards_fieldset {
            flex: 1 1 40%;
          }

          fieldset:last-of-type {
            flex: 1 1 60%;
          }

          ${tablet(
            css`
              ${flex('column')};

              & > fieldset:last-of-type {
                margin-top: ${theme.sizes.SIZE_24};
              }
            `
          )};
        }
      }
    }

    &__condition-tag {
      ${flex('row', 'space-between', 'center')};
      ${elevate_l}

      border-color: var(--PRIMARY_1_1);
      border-radius: ${theme.sizes.SIZE_8};
      padding: ${theme.sizes.SIZE_16};
      width: 100%;

      &__header {
        ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_20)};

        font-weight: 600;
        display: inline-block;
        margin-bottom: ${theme.fontSizes.FONT_22};
      }

      &__radio-btns {
        ${flex('row', undefined, 'center')};

        min-height: ${pxToRem(theme.sizes.SIZE_32)};
        margin-bottom: ${theme.sizes.SIZE_10};

        .wcl-radio-group {
          gap: ${theme.sizes.SIZE_72};
        }

        ${tablet(css`
          .wcl-radio-group {
            gap: ${theme.sizes.SIZE_16};
            ${flex('column')};
          }
        `)}

        ${mobile(
          css`
            ${flex('column')};

            .wcl-radio-group {
              ${flex('column')};

              gap: ${theme.sizes.SIZE_8};

              & > fieldset:last-of-type {
                margin-top: ${theme.sizes.SIZE_24};
              }
            }
          `
        )};
      }

      &__select-destination {
        ${flex('column')};
        margin-left: ${theme.sizes.SIZE_32};
        position: relative;

        ${mobile(
          css`
            margin-top: ${theme.sizes.SIZE_32};
            margin-left: 0;
          `
        )};

        &__btn {
          ${flex()};
          ${elevate_l};

          gap: ${theme.sizes.SIZE_8};
          padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16};
          border-radius: ${theme.sizes.SIZE_32};

          height: ${pxToRem(theme.sizes.SIZE_32)};
          min-height: ${pxToRem(theme.sizes.SIZE_32)};

          span {
            ${fontSize(theme.fontSizes.FONT_11, '17px')};

            font-weight: 400;
            color: var(--SHADE_1_2);

            &:not(:last-of-type)::after {
              content: ',';
              display: inline;
            }
          }

          &--error {
            border: ${theme.sizes.SIZE_1} solid var(--ALERT_ERROR_1_1);
          }
        }
      }

      &__required-lbl {
        ${fontSize(theme.fontSizes.FONT_12)};
        color: var(--SHADE_1_2);

        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(calc(-100% - 4px));
      }

      &__error-lbl {
        ${fontSize(theme.fontSizes.FONT_12)};
        color: var(--ALERT_ERROR_1_1);
        margin-top: ${theme.sizes.SIZE_8};

        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(calc(100%));
        white-space: nowrap;
      }

      .delete-condition {
        ${size(theme.sizes.SIZE_32)};
        min-height: unset;
      }
    }

    &__dates {
      .calendar-container {
        margin-bottom: ${theme.sizes.SIZE_16};

        &:first-child {
          border-right: ${theme.sizes.SIZE_1} solid var(--SHADE_1_6);
          margin-right: ${theme.sizes.SIZE_20};
          padding-right: ${theme.sizes.SIZE_20};

          border-right: none;
          border-bottom: ${theme.sizes.SIZE_1} solid var(--SHADE_1_6);
          padding-bottom: ${theme.sizes.SIZE_32};
        }
      }

      .kinto-select-field,
      .calendar-container {
        .wcl-dropdown {
          width: ${pxToRem(theme.sizes.SIZE_232)};
        }
      }
    }

    &__start-date-options {
      ${flex('row')};
      ${flexGap(theme.sizes.SIZE_12, 'row')};

      .start-date-title {
        ${flex('row', 'flex-start', 'center')};
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

        margin: 0px;
        font-weight: 400;
        color: var(--SHADE_1_1);
        min-height: ${pxToRem('86px')};
        width: ${pxToRem(theme.sizes.SIZE_104)};
      }

      ${phablet(css`
        ${flexGap('0px', 'row')};
        flex-direction: column;

        .start-date-title {
          min-height: ${pxToRem(theme.sizes.SIZE_32)};
        }
      `)}
    }

    &__end-date-options {
      ${flex('row')};
      ${flexGap(theme.sizes.SIZE_12, 'row')};

      .wcl-radio-group {
        width: ${pxToRem(theme.sizes.SIZE_104)};

        &__radio-button {
          justify-content: flex-end;
          min-height: ${pxToRem('86px')};

          span {
            ${flex()};

            white-space: nowrap;
            color: var(--SHADE_1_1);
            line-height: ${pxToRem(theme.sizes.SIZE_16)};
          }
        }
      }

      ${phablet(css`
        flex-direction: column;
        ${flexGap('0px', 'row')};

        .wcl-radio-group {
          margin: 0 0 ${theme.sizes.SIZE_12};

          &__radio-button {
            min-height: ${pxToRem(theme.sizes.SIZE_32)};
          }
        }
      `)}
    }

    &__points-field {
      width: ${pxToRem(theme.sizes.SIZE_256)};

      ${mobile(css`
        width: 100%;
      `)}
    }

    &__reward-repeatable {
      legend {
        color: var(--SHADE_1_1);
        font-weight: 400;
        font-size: ${theme.fontSizes.FONT_16};
        margin: 0;
        align-self: flex-start;
        margin-top: ${theme.sizes.SIZE_24};
      }
    }
  }

  .wcl-select__error-message {
    color: var(--ALERT_ERROR_1_1) !important;
    ${fontSize(theme.fontSizes.FONT_12)};
    margin-top: ${theme.sizes.SIZE_8};
  }
`;
