import { useEffect, useState, useCallback, useContext } from 'react';
import { Icon } from 'components';
import { BaseTemplate } from 'components';
import {
  Button,
  Heading,
  getColor,
  useQueryParams,
} from '@faxi/web-component-library';

import { useNavigate } from 'react-router-dom';
import { apiUser } from 'modules';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'store';

const errorVerifyCodes = [1037, 9008, 1012, 9023];

const ActivatePage = (): JSX.Element => {
  const { platform } = useContext(AppContext);

  const [message, setMessage] = useState('');
  const [icon, setIcon] = useState('');

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    params: { code },
  } = useQueryParams<{ code: string; oid: string }>();

  const verifyUser = useCallback(async () => {
    const res = await apiUser.verifyUser(code);

    //9008 - missing parameter
    //1037 - code not generated
    //1012 - not activated, db error
    //9023 - already verified
    if (errorVerifyCodes.includes(res.errc)) {
      setMessage(t(`error_${res.errc}`));
      setIcon(`${res.errc}`);
    } else {
      setMessage(t('verify_email_message'));
      setIcon('success');
    }
  }, [code, t]);

  useEffect(() => {
    if (platform) {
      verifyUser();
    }
  }, [verifyUser, platform]);

  return (
    <BaseTemplate baseClassName="activate-page">
      <div className="activate-page__main">
        <div className="activate-page__main__info-box">
          <div className="activate-page__main__info-box__body">
            {(icon === '1037' || icon === '9008' || icon === '1012') && (
              <Icon
                name="ban"
                className="activate-page__main__info-box__body__icon"
                color={getColor('--ALERT_ERROR_1_1')}
              />
            )}
            {icon === '9023' && (
              <Icon
                name="exclamation-circle"
                className="activate-page__main__info-box__body__icon"
                color={getColor('--ALERT_WARNING_1_1')}
              />
            )}
            {icon === 'success' && (
              <Icon
                name="check-circle"
                className="activate-page__main__info-box__body__icon"
                color={getColor('--ALERT_SUCCESS_1_1')}
              />
            )}

            <div className="activate-page__main__info-box__body__content">
              <Heading level="1" color={getColor('--SHADE_2_1')}>
                {message}
              </Heading>
              <Button
                onClick={() => {
                  navigate('..');
                }}
              >
                {t('new_onboarding_log_in')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </BaseTemplate>
  );
};

export default ActivatePage;
