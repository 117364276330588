import { theme } from '@faxi/web-component-library';
import { flex, fontSize, phablet, size } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const Home = styled.div`
  ${flex('column', 'center', 'center')};
  ${size('100%')};

  p {
    ${fontSize(theme.fontSizes.FONT_20, theme.sizes.SIZE_32)};

    margin: 0;
    font-weight: 400;
    text-align: center;
    color: var(--SHADE_1_2);
    max-width: ${theme.sizes.SIZE_520};
  }

  .kinto-user-home {
    &__placeholder {
      max-width: 100%;
      max-height: ${theme.sizes.SIZE_374};

      ${phablet(css`
        max-height: ${theme.sizes.SIZE_264};
      `)}
    }

    &__title {
      ${fontSize(theme.fontSizes.FONT_44, theme.sizes.SIZE_64)};

      font-weight: 600;
      color: var(--PRIMARY_1_1);
      margin: 0 0 ${theme.sizes.SIZE_12};
    }

    &__description {
      margin: 0 0 ${theme.sizes.SIZE_32};
    }
  }
`;
