import httpClient from '../httpClient';
import { Language, Platform } from 'models';
import { AxiosRequestConfig } from 'axios';

const getPlatforms = async (domain?: string, config?: AxiosRequestConfig) =>
  httpClient
    .get<{ platforms: Platform[] }>('platform/details', {
      ...config,
      params: {
        domain: domain || null,
      },
    })
    .then((res) => res.data.platforms);

const getLanguages = async () =>
  httpClient
    .get<{ languages: Language[] }>('platform/available-languages')
    .then((res) => res.data);

const resendEmaiLVerification = (email: string) => {
  const formdata = new FormData();
  formdata.append('email', email);

  return httpClient.post('user/resendemail', formdata);
};

export default {
  getPlatforms,
  getLanguages,
  resendEmaiLVerification,
};
