import { useContext, useState, useMemo, FC } from 'react';
import { FormField, DataState, FormRef } from '@faxi/web-form';
import {
  Heading,
  getColor,
  useCallbackRef,
  useEffectOnceWhen,
  useFormButtons,
  useUtilities,
} from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { FormActions } from 'components';
import { AppContext, UserContext } from 'store';
import CheckboxInfoField from 'components/_molecules/CheckboxInfoField';
import { ConsentsPayload } from 'models';
import { apiConsents } from 'modules';
import Each from 'helpers/Each';
import { useCallbackAsync } from 'hooks';

import * as Styled from './DataConsentsForm.styles';
import { PageLayout } from 'components/_layouts';

declare global {
  interface String {
    parametrify(): string;
  }
}

// eslint-disable-next-line no-extend-native
String.prototype.parametrify = function (this: string) {
  return this.toLowerCase().replace(new RegExp(' ', 'g'), '_');
};

const DataConsentsForm: FC = (): JSX.Element => {
  const { t } = useTranslation();

  const { platform } = useContext(AppContext);
  const { user, userReady, getConsents } = useContext(UserContext);

  const { showSnackBar } = useUtilities();

  const [consentsPayload, setConsentsPayload] = useState<ConsentsPayload>();

  const [loadConsents] = useCallbackAsync({
    callback: async () => {
      setConsentsPayload(await getConsents());
    },
    condition: userReady,
    showSpinner: true,
    spinnerParent: '.kinto-page',
  });

  const [handleSubmit] = useCallbackAsync({
    callback: async (data: DataState) => {
      const formattedData: any[] = [];

      Object.keys(data).forEach((key) => {
        formattedData.push({ key: key, val: data[key] ? 'Y' : 'N' });
      });

      Promise.all([
        await apiConsents.uploadConsents(user!.id, formattedData, platform!.id),

        await loadConsents(),
      ]);
    },
    showSpinner: true,
    spinnerParent: '.kinto-page',
    condition: !!(user && platform),
    onFinish: () =>
      showSnackBar({
        actionButtonText: t('dismiss'),
        text: t('consents_successfully_updated'),
        variant: 'success',
      }),
    onError: () =>
      showSnackBar({
        actionButtonText: t('dismiss'),
        text: t('error400_cta'),
        variant: 'error',
      }),
  });

  const [FormButtons] = useFormButtons({
    submitLabel: t('Save'),
    cancelLabel: t('cancel'),
    loadingOverlaySelector: '.kinto-page',
  });

  const [form, formRef] = useCallbackRef<FormRef>();

  const initialData = useMemo(() => {
    const obj = {};

    consentsPayload?.consents.forEach(({ id, value }) => {
      Object.assign(obj, { [id]: value });
    });

    return obj;
  }, [consentsPayload]);

  useEffectOnceWhen(() => {
    loadConsents();
  }, userReady);

  return (
    <PageLayout className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('data_usage_consent')}
      </Heading>
      <Styled.Form
        id="data_use_consent_form"
        onSubmit={handleSubmit}
        className="consents__form"
        initialData={initialData}
        ref={formRef}
      >
        <fieldset>
          <legend data-hidden hidden>
            {t('data_usage_consent')}
          </legend>
          {consentsPayload?.consents && (
            <Each
              of={consentsPayload?.consents}
              render={({ id, titleKey, subtitleKey }) => (
                <FormField
                  className="consents__box"
                  id={id}
                  key={id}
                  name={id}
                  component={CheckboxInfoField}
                  label={t(titleKey)}
                  labelPosition="left"
                  description={parse(t(subtitleKey))}
                />
              )}
            />
          )}
        </fieldset>

        <FormActions className="form__actions">
          <FormButtons.Submit
            id="submit_data_use_consent"
            disabled={!form?.isFormChanged() || !form?.syncFormValid}
          />
        </FormActions>
      </Styled.Form>
    </PageLayout>
  );
};

export default DataConsentsForm;
