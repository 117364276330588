import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '@faxi/web-component-library';

import * as Styles from './Home.styles';

const Home: FC = () => {
  const { t } = useTranslation();

  return (
    <Styles.Home className="kinto-user-home">
      <h2 className="kinto-user-home__title">
        {t('navigation_home-empty_state_title_coming_soon')}
      </h2>

      <p className="kinto-user-home__description">
        {t('navigation_home-empty_state_subtitle_coming_soon')}
      </p>

      <Image
        alt=""
        className="kinto-user-home__placeholder"
        src="/assets/images/user-home-placeholder.webp"
      />
    </Styles.Home>
  );
};

export default Home;
