import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import parse from 'html-react-parser';

import { ModalProps } from '@faxi/web-component-library';
import { ButtonCard } from 'components';
import { INameExtended } from 'components/Icon';
import Each from 'helpers/Each';
import { appUri } from 'config';

import * as Styled from './CampaignTypeModal.styles';

type CampaignTypeModalProps = Partial<ModalProps>;

const CampaignTypeModal = (props: CampaignTypeModalProps) => {
  const { className, ...rest } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const rewardTypes = useMemo(
    () => [
      {
        icon: 'route' as INameExtended,
        title: t('rewards-title_regular_campaign'),
        description: t('rewards-subtitle_regular_campaign'),
        onClick: () => {
          navigate(appUri.GAMIFICATION_CAMPAIGNS_CREATE_NEW);
        },
      },
      // {
      //   icon: 'circle-star' as INameExtended,
      //   title: t('rewards-title_accumulation_campaign'),
      //   description: parse(
      //     t('rewards-subtitle_accumulation_campaign').replace(/\\n/g, '<br />')
      //   ) as string,
      //   onClick: () => {
      //     navigate({
      //       pathname: 'create-new',
      //       search: 'type=accumulation',
      //     });
      //   },
      // },
    ],
    [navigate, t]
  );

  return (
    <Styled.Modal
      className="campaign-type-modal"
      title={t('gamification-campaigns_title_create_a_campaign')}
      {...rest}
    >
      <div className="campaign-type-modal__subtitle">
        {t('rewards-title_choose_campaign')}
      </div>

      <Each of={rewardTypes} render={(type) => <ButtonCard {...type} />} />
    </Styled.Modal>
  );
};

export default CampaignTypeModal;
