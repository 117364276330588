import React from 'react';
import classNames from 'classnames';
import Icon, { INameExtended } from 'components/Icon';
import { ButtonProps } from '@faxi/web-component-library';

import * as Styled from './ButtonCard.styles';

export type ButtonCardProps = {
  title: string;
  description?: string;
  icon?: INameExtended;
  className?: string;
} & ButtonProps;

const ButtonCard = (props: ButtonCardProps) => {
  const { title, description, icon, className, ...rest } = props;

  return (
    <Styled.ButtonCard
      className={classNames(className)}
      variant="ghost"
      {...rest}
    >
      {icon && <Icon className="button-card__button-icon" name={icon} />}
      <div>
        <div className="button-card__title">{title}</div>
        <p className="button-card__description">{description}</p>
      </div>
      <Icon className="button-card__chevron-right" name="chevron-right" />
    </Styled.ButtonCard>
  );
};

export default ButtonCard;
