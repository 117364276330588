import { createRoot } from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from 'config/microsoftAuthConfig';
import App from './App';

import 'modules/i18n';
import 'components/Icon';
import 'tippy.js/dist/tippy.css';

declare global {
  interface Window {
    OverlappingMarkerSpiderfier: any;
    viewChangesBtnRef: HTMLElement;
    google?: {
      accounts: any;
    };
    AppleID?: {
      auth: any;
    };
  }
}

const container = document.getElementById('root');
const root = createRoot(container as Element);

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
);
