import { AxiosResponse } from 'axios';
import { LoginPlatform, RefreshTokenResponse } from 'models';
import httpClient from '../httpClient';

const login = async (formData: FormData) =>
  httpClient
    .post('session', formData, { params: { version: 2 } })
    .then((res) => res.data);

const invalidateSession = (sid: string) =>
  httpClient.post('session', { sid }, { params: { _method: 'PUT' } });

const refreshToken = (refresh_token: string):
Promise<AxiosResponse<RefreshTokenResponse>> => {
  const formData = new FormData();
  formData.append('refresh_token', refresh_token);

  return httpClient.post<RefreshTokenResponse>('auth/refresh', formData, {
    isRefresh: true,
  } as any);
};

const socialLogin = async (provider: LoginPlatform, token: string) => {
  const formData = new FormData();
  formData.append('token', token);
  formData.append('provider', provider);
  formData.append('web', '1');

  return httpClient.post(`/social`, formData).then((res) => res.data);
};

export default {
  login,
  socialLogin,
  invalidateSession,
  refreshToken,
};
