import { FC, useContext } from 'react';

import { apiAuth } from 'modules';
import { AuthContext } from 'store';
import { SocialButton } from 'components';
import { APPLE_SCRIPT_SRC } from './utils';
import { useCallbackAsync, useScript } from 'hooks';
import useAppleLogin, { AppleAuthResponse } from './hooks/useAppleLogin';
import env from 'env';

const Apple: FC = () => {
  const { applyCredentials } = useContext(AuthContext);
  const { scriptLoaded } = useScript(APPLE_SCRIPT_SRC);

  const [successAppleAuth] = useCallbackAsync({
    showSpinner: true,
    catchAsyncErrors: false,
    callback: async (response: AppleAuthResponse) => {
      const { authorization } = response;
      if (!authorization) return;

      const authData = await apiAuth.socialLogin(
        'apple',
        authorization.id_token
      );

      applyCredentials(authData);
    },
  });

  const { authenticate } = useAppleLogin({
    onSuccess: successAppleAuth,
    scriptLoaded,
    authOptions: {
      clientId: env.VITE_APP_APPLE_CLIENT_ID,
      redirectURI: `${window.location.origin}/login`,
      usePopup: true,
      scope: 'name email',
    },
  });

  return <SocialButton provider="apple" onClick={authenticate} />;
};

export default Apple;
