import { SortDirections } from '@faxi/web-component-library';
import { AxiosRequestConfig } from 'axios';
import {
  ApiUser,
  StatisticType,
  CustomFields,
  PaginatedResponse,
  PeoplePageUser,
} from 'models';

import httpClient from '../httpClient';

const getPeople = async (args: {
  organisationId: number;
  count: number;
  offset: number;
  search: string;
  status?: string[];
  transport_type?: string[];
  role?: string[];
  unit?: string;
  sort_by?: Partial<keyof PeoplePageUser>;
  sort_direction?: SortDirections;
  config?: Partial<AxiosRequestConfig>;
}) => {
  const { organisationId, config, ...restArgs } = args;

  return httpClient
    .get<PaginatedResponse<PeoplePageUser, 'users'>>(
      `admin/${organisationId}/users`,
      {
        params: {
          ...restArgs,
        },
        ...config,
      }
    )
    .then((res) => res.data);
};

// TODO: why duplicate?
const getOrganisationPendingStatus = async (
  organisationId: number,
  status: string
) =>
  httpClient
    .get(`admin/${organisationId}/users`, {
      params: {
        status,
      },
    })
    .then((res) => res.data);

const getExactUser = async (
  organisationId: number,
  userId: string,
  config?: Partial<AxiosRequestConfig>
) =>
  httpClient
    .get<{ users: ApiUser[] }>(`admin/${organisationId}/user/${userId}`, {
      ...config,
    })
    .then((res) => res.data);

const getUserStatistic = async (adminId: string, oid: number, userId: string) =>
  httpClient
    // COMMENT: note hardcoded value
    .get<{ stats: StatisticType }>(`user/${adminId}/stat`, {
      params: { uid: userId, oid },
    })
    .then((res) => res.data);

const getCustomFields = async (organisationId: number, userId: string) =>
  httpClient
    .get<{ custom_fields: CustomFields[] }>(
      `admin/${organisationId}/user/${userId}/custom_fields`
    )
    .then((res) => res.data);

const approveSelectedPeople = async (
  organisationId: number,
  userListIds: string[]
) => {
  const formData = new FormData();
  formData.append('oid', `${organisationId}`);
  formData.append('uids', userListIds.toString());

  return httpClient
    .post<{ rc: 'ok' | 'error' }>('/admin/approve', formData, {
      params: {
        _method: 'PUT',
      },
    })
    .then((res) => res.data);
};

const reactivateSelectedPeople = async (
  organisationId: number,
  userListIds: string[]
) => {
  const formData = new FormData();
  formData.append('oid', `${organisationId}`);
  formData.append('uids', userListIds.toString());

  return httpClient
    .post<{ rc: 'ok' | 'error' }>('/admin/reactivate', formData, {
      params: {
        _method: 'PUT',
      },
    })
    .then((res) => res.data);
};

const rejectSelectedPeople = async (
  organisationId: number,
  userListIds: string[]
) => {
  const formData = new FormData();
  formData.append('oid', `${organisationId}`);
  formData.append('uids', userListIds.toString());

  return httpClient
    .post<{ rc: 'ok' | 'error' }>('/admin/reject', formData, {
      params: {
        _method: 'PUT',
      },
    })
    .then((res) => res.data);
};

const deactivateSelectedPeople = async (
  organisationId: number,
  userListIds: string[]
) => {
  const formData = new FormData();
  formData.append('oid', `${organisationId}`);
  formData.append('uids', userListIds.toString());

  return httpClient
    .post<{ rc: 'ok' | 'error' }>('/admin/deactivate', formData, {
      params: {
        _method: 'PUT',
      },
    })
    .then((res) => res.data);
};

const promoteUserToAdmin = async (
  flag: string,
  organisationId: number,
  uid: string
) => {
  const formData = new FormData();
  formData.append('flag', flag);
  formData.append('oid', `${organisationId}`);
  formData.append('uid', uid);

  return httpClient
    .post<{ rc: 'ok' | 'error'; errc: number }>('admin/orgadmin', formData, {
      params: { _method: 'PUT' },
    })
    .then((res) => res.data);
};

export default {
  getPeople,
  getExactUser,
  approveSelectedPeople,
  rejectSelectedPeople,
  deactivateSelectedPeople,
  reactivateSelectedPeople,
  getUserStatistic,
  getCustomFields,
  promoteUserToAdmin,
  getOrganisationPendingStatus,
};
