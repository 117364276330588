import styled from 'styled-components';
import { Form as FormComponent } from '@faxi/web-form';

export const Form = styled(FormComponent)`
  .consents {
    &__box {
      display: grid;
      grid-template-columns: 1fr auto;
      width: 100%;
    }
  }
`;
