import { ObjectValues } from './Generics';
import { NotificationPerCommunity } from './NotificationPreferences';

export const PREMIUM_FEATURES = {
  CARPOOLING: 'carpooling',
  CYCLING: 'cycling',
  EVENT_FEATURE: 'event_feature',
  GAMIFICATION_FEATURE: 'gamification_feature',
  JOURNEYS_PAGE: 'journeys_page',
  MESSAGES_PAGE: 'messages_page',
  PLANNER_PAGE: 'planner_page',
  WALKING: 'walking',
  SURVEY: 'survey',
} as const;
export type PremiumFeatureType = ObjectValues<typeof PREMIUM_FEATURES>;

export type PremiumFeature = {
  id: number;
  name: PremiumFeatureType;
};

export type Community = {
  name: string;
  // WHEN RETRIEVED AS ONE, IT'S A NUMBER, WHEN RETRIEVED AS MANY, IT'S A STRING, API :D
  id: number | string;
  phone: string;
  email: string;
  address1: string;
  city: string;
  postcode: string;
  country: string;
  county: string;
  rightprofile: 'A' | '-' | undefined;
  pending_requests: number | undefined;
  description: string;
  include_test_users: 'Y' | 'N';
  status: 'active' | 'hidden' | 'pending';
  currency: string;
  domain?: string;
  preapproved_emails?: string;
  premium_features: PremiumFeature[];
  lat?: string;
  lng?: string;
  imgSrc?: string;
  image_url?: string;
  approved_users?: number;
  'approved_users-test'?: number;
  journeys_limit?: number;
  expected_size?: number;
  support_email?: string;
  formatted_address?: string;
  show_address?: number;
} & Partial<NotificationPerCommunity>;

export type AddressPlace = {
  lat: number;
  lng: number;
  formatted_address?: string;
  address?: string;
  country?: string;
  locality?: string;
  administrative_area_level_1?: string;
  postal_code?: string;
  city?: string;
};

export type InviteCodes = {
  code: string;
  joincode?: string;
};
