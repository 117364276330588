import {
  DataConsentsForm,
  UpdateAddressForm,
  UpdateProfileForm,
  SettingsPasswordForm,
  UserPreferencesForm,
  ChangeEmailForm,
} from 'components';
import EmailNotificationsTab from 'pages/MyAccount/tabs/EmailNotifications';
import { Navigate } from 'react-router-dom';
import { ExtendRouteObject } from 'router/routes';
import { appUri } from '../../../config';

export default [
  {
    handle: 'Update profile',
    path: appUri.ACCOUNT_SETTINGS_UPDATE_PROFILE,
    element: <UpdateProfileForm />,
  },
  {
    handle: 'Update address',
    path: appUri.ACCOUNT_SETTINGS_UPDATE_ADDRESS,
    element: <UpdateAddressForm />,
  },
  {
    handle: 'Change email',
    path: appUri.ACCOUNT_SETTINGS_CHANGE_EMAIL,
    element: <ChangeEmailForm />,
  },
  {
    handle: 'Password',
    path: appUri.ACCOUNT_SETTINGS_PASSWORD,
    element: <SettingsPasswordForm />,
  },
  {
    handle: 'User preferences',
    path: appUri.ACCOUNT_SETTINGS_USER_PREFERENCES,
    element: <UserPreferencesForm />,
  },
  {
    handle: 'Email notifications',
    path: appUri.ACCOUNT_SETTINGS_EMAIL_NOTIFICATIONS,
    element: <EmailNotificationsTab />,
  },
  {
    handle: 'Data usage consent',
    path: appUri.ACCOUNT_SETTINGS_DATA_USAGE_CONSENTS,
    element: <DataConsentsForm />,
  },
  {
    index: true,
    element: <Navigate to={appUri.ACCOUNT_SETTINGS_UPDATE_PROFILE} replace />,
  },
] as ExtendRouteObject[];
