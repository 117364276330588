import {
  useState,
  useCallback,
  useEffect,
  useContext,
  FC,
  useMemo,
  PropsWithChildren,
} from 'react';
import { useEffectSkipFirst, useUtilities } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import { Method } from 'axios';
import i18n from 'i18next';

import { AppContext, AuthContext } from 'store';
import {
  apiCommunity,
  apiUser,
  apiConsents,
  authBus,
  AUTH_BUS_EVENTS,
} from 'modules';
import {
  Community,
  Place,
  PREMIUM_FEATURES,
  User,
  UserPreferences,
} from 'models';
import storageService, { STORAGE_KEYS } from 'services/storageService';

import UserContext from './User.context';
import { useParams } from 'react-router-dom';
import { orderBy } from 'lodash';
import { Role } from '../../models/User';

export const PERMISSIONS_MAP = {
  0: ['user'],
  1: ['user', 'admin'],
} as Record<number, Role[]>;

// TODO:
// where only setCommunities is called, probably move to Community provider

const UserProvider: FC<PropsWithChildren<any>> = (props) => {
  const { children } = props;

  const { t } = useTranslation();

  const { platform } = useContext(AppContext);
  const { sessionId } = useContext(AuthContext);

  const { organisationId } = useParams() as {
    organisationId: string;
  };

  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const [user, setUser] = useState<User>();
  const [communities, setCommunities] = useState<Community[]>([]);
  const [userReady, setUserReady] = useState<boolean>(false);
  const [userPreferences, setUserPreferences] = useState<UserPreferences>({
    dateFormat: 'DD.MM.YYYY',
    unit: 'km',
  });

  const [communityId, setCommunityId] = useState<number | undefined>(
    +organisationId ||
      Number(storageService.getItem(STORAGE_KEYS.COMMUNITY_ID)) ||
      undefined
  );

  const community = useMemo(
    () => communities.find((c) => c.id === +communityId!),
    [communities, communityId]
  );

  const navigationItemRoute = useCallback(
    (urlFunction: (id: string) => string): string =>
      urlFunction(`${communityId}`),
    [communityId]
  );

  const premiumFeatureAvailable = useCallback(
    (feature: keyof typeof PREMIUM_FEATURES) =>
      !!community?.premium_features?.find(
        ({ name }) => name === PREMIUM_FEATURES[feature]
      ),
    [community]
  );

  const includeTestUsers = useMemo(
    () => community?.include_test_users === 'Y',
    [community]
  );

  const isValidCommunity = useMemo(
    () => communities.some((c) => c.id === communityId),
    [communities, communityId]
  );

  const communityUsersExist = useMemo(
    () =>
      Number(community?.approved_users) +
        Number(community?.['approved_users-test']) >
      1,
    [community]
  );

  const isAdminAtLeastOnce = useMemo(
    () => !!user?.organisations?.some((v) => v.rightprofile === 'A'),
    [user]
  );

  const adminForCommunity = useMemo(() => {
    const community = user?.organisations?.find(
      (org) => org.id === `${communityId}`
    );

    return community?.rightprofile === 'A';
  }, [communityId, user]);

  const addOrganisation = useCallback((newCommunity: Community) => {
    setCommunities((old) =>
      orderBy(
        [...old, newCommunity],
        [(comm) => comm.name.toLowerCase()],
        ['asc']
      )
    );
  }, []);

  const getConsents = useCallback(async () => {
    if (!(user && platform)) {
      return;
    }

    return apiConsents.getConsents(user.id, platform.id);
  }, [platform, user]);

  const updateCommunity = useCallback(
    async (oid: number, data: object, method?: Method) => {
      try {
        const result = await apiCommunity.updateCommunity(oid, data, method);

        setCommunities((old) =>
          old.map((c) => {
            if (c.id === oid) {
              const image_url =
                method === 'DELETE' ? '' : result?.image_url || c.image_url;

              return { ...c, ...data, image_url };
            } else return c;
          })
        );

        setUser(
          (old) =>
            ({
              ...old,
              places: old?.places?.map((place: Place) =>
                place.ido === `${oid}`
                  ? {
                      ...place,
                      lat: (data as Community).lat,
                      lng: (data as Community).lng,
                    }
                  : place
              ),
            } as User)
        );
        return result;
      } catch (e) {
        console.error(e);
        showSnackBar({
          actionButtonText: t('dismiss'),
          text: t('community_update_err'),
          variant: 'error',
        });
      }
    },
    [showSnackBar, t]
  );

  const updateUserAdminPermission = useCallback(
    (organistionId: number, isAdmin: boolean) => {
      const sidebar = document.querySelector('.sidebar__items');
      sidebar?.scrollTo({ behavior: 'smooth', top: 0 });

      setCommunities((old) =>
        old.map((c) =>
          c.id === organistionId
            ? { ...c, rightprofile: isAdmin ? 'A' : '-' }
            : c
        )
      );
    },
    []
  );

  // TODO:
  // consider creating one generic function for these...
  const updateTestUserInOrganisation = useCallback(
    (oid: number, testUserIncluded: boolean) => {
      setCommunities((old) =>
        old.map((c) =>
          c.id === oid
            ? { ...c, include_test_users: testUserIncluded ? 'Y' : 'N' }
            : c
        )
      );
    },
    []
  );

  const updateCommunityPendingRequests = useCallback(
    (oid: number, newPendingRequests: number) => {
      setCommunities((old) =>
        old.map((c) =>
          c.id === oid ? { ...c, pending_requests: newPendingRequests } : c
        )
      );
    },
    []
  );

  const updateUser = useCallback(
    async (userId: string, data: object, method?: Method) => {
      const res = await apiUser.updateUser(userId, data, method);

      setUser((old) => {
        const image_url =
          method === 'DELETE' ? '' : res?.image_url || old?.image_url;

        return { ...old!, ...data, image_url };
      });
      return res;
    },
    []
  );

  const updateUserPreferences = useCallback(
    async (key: string, value: string) => {
      try {
        await apiUser.updateUser(user!.id, { key, value }, 'PUT');

        if (key === 'units') {
          setUserPreferences((old) => ({ ...old, unit: value as 'km' | 'mi' }));
        } else {
          setUserPreferences((old) => ({ ...old, dateFormat: value }));
        }
      } catch (e) {
        console.error(e);
      }
    },
    [user]
  );

  const getUser = useCallback(
    async (userId?: string, key?: string) => apiUser.getUser(userId, key),
    []
  );

  const removeDeactivatedCommunity = useCallback(() => {
    setCommunities((old) => old.filter((c) => c.id === communityId));
  }, [communityId]);

  const fetchUser = useCallback(async () => {
    document.body.style.overflow = 'hidden';
    showOverlay('body', 'fixed');

    try {
      const res = await apiUser.getUser();

      if (res.id) {
        const {
          data: { organisations },
        } = await apiCommunity.getPendingRequests(res.id);

        setUser(res);

        setCommunities(
          orderBy(organisations, [(comm) => comm.name.toLowerCase()], ['asc'])
        );

        i18n.changeLanguage(res.lang);
      }

      const { value: dateFormatValue } = await apiUser.getUser(
        res.id,
        'date_format'
      );

      const { value: unitValue } = await apiUser.getUser(res.id, 'units');

      if (dateFormatValue && unitValue) {
        setUserPreferences((old) => ({
          ...old,
          unit: unitValue as 'km' | 'mi',
          dateFormat: dateFormatValue,
        }));

        setUserReady(true);
      }
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('body');
      document.body.style.overflow = 'initial';
    }
  }, [hideOverlay, showOverlay]);

  useEffect(() => {
    if (sessionId) {
      fetchUser();
    }
  }, [fetchUser, sessionId]);

  // UNIT AND DATE FORMAT FROM LOCALSTORAGE
  useEffect(() => {
    const localStorageUnit = storageService.getItem(STORAGE_KEYS.UNIT);
    const localStorageDateFormat = storageService.getItem(
      STORAGE_KEYS.DATE_FORMAT
    );

    if (localStorageUnit) {
      setUserPreferences((old) => ({
        ...old,
        unit: localStorageUnit as 'km' | 'mi',
      }));
    }

    if (localStorageDateFormat) {
      setUserPreferences((old) => ({
        ...old,
        dateFormat: localStorageDateFormat as string,
      }));
    }
  }, []);

  // REMOVE COMMUNITY IF IT'S DEACTIVATED
  useEffect(() => {
    authBus.addEventListener(
      AUTH_BUS_EVENTS.NON_EXISTING_COMMUNITY,
      removeDeactivatedCommunity
    );

    return () => {
      authBus.removeEventListener(
        AUTH_BUS_EVENTS.NON_EXISTING_COMMUNITY,
        removeDeactivatedCommunity
      );
    };
  }, [removeDeactivatedCommunity]);

  useEffectSkipFirst(() => {
    if (!sessionId) {
      setUser(undefined);
      setCommunities([]);
      setCommunityId(undefined);
    }
  }, [sessionId]);

  return (
    <UserContext.Provider
      value={{
        user,
        userReady,
        userPreferences,
        communityUsersExist,
        includeTestUsers,
        isValidCommunity,
        communities,
        community,
        isAdminAtLeastOnce,
        adminForCommunity,
        setUser,
        addOrganisation,
        getUser,
        getConsents,
        updateCommunity,
        updateCommunityPendingRequests,
        updateUser,
        updateTestUserInOrganisation,
        updateUserAdminPermission,
        updateUserPreferences,
        communityId,
        setCommunityId,
        navigationItemRoute,
        premiumFeatureAvailable,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
