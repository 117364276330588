import { createContext } from 'react';
import { RewardType } from 'models';

type CampaignContext = {
  rewards?: RewardType[];
};

export default createContext<CampaignContext>({
  rewards: [],
});
