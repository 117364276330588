import { focusStyles, theme } from '@faxi/web-component-library';
import {
  flex,
  fontSize,
  phablet,
  position,
  pxToRem,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const PointsPage = styled.div`
  .kinto-points-form {
    margin-top: ${theme.sizes.SIZE_32};
    border-radius: ${theme.sizes.SIZE_8};
    background-color: var(--BACKGROUND_1_1);
    max-width: ${pxToRem(theme.sizes.SIZE_1016)};
    border: ${theme.sizes.SIZE_1} solid var(--SHADE_1_7);

    &__header {
      display: flex;
      position: relative;
      gap: ${theme.sizes.SIZE_16};
      padding: ${theme.sizes.SIZE_20};

      ${phablet(css`
        padding: ${theme.sizes.SIZE_16};
      `)};

      &__switch {
        ${position('absolute', `top ${theme.sizes.SIZE_20}`)};
      }

      &__container {
        ${flex('column')};

        gap: ${theme.sizes.SIZE_8};
        padding-left: ${theme.sizes.SIZE_72};

        &__title {
          ${fontSize(theme.fontSizes.FONT_20, theme.sizes.SIZE_20)};
          ${focusStyles};

          margin: 0;
          font-weight: 600;
          color: var(--SHADE_1_1);
        }

        &__description {
          ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
          ${focusStyles};

          color: var(--SHADE_1_2);
          font-weight: 400;
          margin-right: ${theme.sizes.SIZE_48};
          max-width: ${theme.sizes.SIZE_632};
        }
      }
    }
  }
`;
