import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet } from 'react-router-dom';
import { Image } from '@faxi/web-component-library';
import { Footer, ScrollToTop } from 'components';
import LanguageSelector from 'components/_molecules/LanguageSelector';

import * as Styled from './LoginTemplated.styles';

const LoginTemplate: FC<
  PropsWithChildren<{
    imageUrl?: string;
    headerClassName?: string;
    mainClassName?: string;
    footerClassName?: string;
    renderOutlet?: boolean;
  }>
> = (props): JSX.Element => {
  const {
    children,
    imageUrl = '/assets/images/Loging.webp',
    headerClassName,
    mainClassName,
    footerClassName,
    renderOutlet = true,
  } = props;

  const { t } = useTranslation();

  return (
    <Styled.Container id="login-container" className="template">
      <ScrollToTop targetNodeId="login-container" />

      <header className={classNames('template__header', headerClassName)}>
        <NavLink to="/login">
          <Image alt="" src="/assets/svg/kinto_join_logo_stacked.svg" />
        </NavLink>

        <LanguageSelector />
      </header>

      <main className={classNames('template__main', mainClassName)}>
        {renderOutlet ? <Outlet /> : children}

        <Image
          className=" template__main__image"
          src={imageUrl}
          alt={t('app_name')}
        />
      </main>

      <div className={classNames('template__footer', footerClassName)}>
        <Footer />
      </div>
    </Styled.Container>
  );
};

export default LoginTemplate;
