import { createGlobalStyle, css } from 'styled-components';
import {
  position,
  size,
  flex,
  marginChildren,
  laptop,
  mobile,
  mobileLandscape,
  phablet,
  tablet,
  fontSize,
  flexGap,
  pxToRem,
} from '@faxi/web-css-utilities';

import { elevate_xl, focusStyles, theme } from '@faxi/web-component-library';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    text-size-adjust: none;
  }

  html, body {
    height: 100%;
  }

  #root {
    display: grid;
    width: 100%;
    height: 100%;
  }

  fieldset {
    border: unset;
    margin: unset;
    padding: unset;

    legend[data-hidden="true"]{
      ${size('0')};
      opacity: 0;
    }
  }

  #snack-bar-container[id] {
    z-index: 10000;
  }

  a {
    text-decoration: none;
  }

  .wcl-portal {
    .wcl-dropdown__content {
      z-index: 10000;
    }

    .wcl-calendar {
      border: unset;
    }

    &.generate-reports-menu{
      .wcl-dropdown__content{
        z-index: 1;
        max-width: ${pxToRem(theme.sizes.SIZE_200)};
      }
    }

    &.select-schedule__menu{
      .wcl-dropdown__content{
        width: fit-content;
      }
    }
  }

  .kinto-visually-hidden {
    ${size(theme.sizes.SIZE_1)};

    border: 0;
    clip: rect(0 0 0 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }

  .end-survey-prompt{
    .prompt-modal__content{
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

      margin: 0;
      padding: 0;
      font-weight: 400;
      color: var(--SHADE_1_2);
    }
  }

  .prompt-modal{
    &.deactivate-campaign-prompt{
      .prompt-modal__content{
        padding: 0;
      }
    }
  }

  .points-prompt-modal{
    &.activate{
      .wcl-modal__footer{
        ${flexGap('0px', 'row')};
        ${flexGap(theme.sizes.SIZE_48, 'row-reverse')};

        flex-direction: row-reverse;
      }
    }

    .prompt-modal__content{
      padding: unset;
    }

    .kinto-points-form__alert-content {
      ${flex('row')};
      ${fontSize(theme.sizes.SIZE_16, theme.sizes.SIZE_24)}

      gap: ${theme.sizes.SIZE_8};

      padding: ${theme.sizes.SIZE_20};
      border-radius: ${theme.sizes.SIZE_8};

      &--activate {
        background-color: var(--PRIMARY_1_7);
      }

      &--deactivate{
        background-color: var(--LABEL_TEXT_1_7);
      }
    }
  }
  
  body {
    margin: 0;
  }

  * {
    font-family: ToyotaType !important;
  }

  #onetrust-consent-sdk {
    position: absolute;
  }
  
  button {
    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .map {
    .gm-style {
      font-family: ToyotaType;

      div[role='button'] {
        &, > img {
          &:focus {
            outline: none;
          }
        }
      }

      &-iw {
        &-tc {
          transform: rotate(180deg) translateY(100%) translateX(50%) !important;
          filter: none !important;
        }

        &-c {
          padding: ${theme.sizes.SIZE_20} !important;
          max-width: 100%;
          border-radius: ${theme.sizes.SIZE_8};
          max-height: inherit !important;
          ${elevate_xl};
          transform: translateX(-50%) !important;
        }

        &-t {
          &:after {
            content: none;
          }

          > div {
            top: ${theme.sizes.SIZE_88};
            transform: none;
            outline: none;

            > div {
              padding: 0;
              overflow: hidden !important;
              max-height: inherit !important;
            }

            > button {
              width: ${pxToRem(theme.sizes.SIZE_20)} !important;
              height: ${pxToRem(theme.sizes.SIZE_20)} !important;

              top: ${theme.sizes.SIZE_16} !important;
              right: ${theme.sizes.SIZE_16} !important;
              display: flex !important;
              align-items: center;
              justify-content:center;
              position: relative;
              opacity: 1;

              > img {
                display: none !important;
              }

              > span {
                display: none !important;
              }

              &::after, &::before {
                ${size(theme.sizes.SIZE_16, theme.sizes.SIZE_2)};
                ${position('absolute', 'top 50% right 50%')};

                content: '';
                display: block;
                background-color: var(--PRIMARY_1_3);
              }

              &::before {
                transform: translate(50%, 50%) rotate(45deg);
              }

              &::after {
                transform: translate(50%, 50%) rotate(-45deg);
              }
            }
          }

          .info-window {
            bottom: 0 !important;

            &__container {
              padding: ${theme.sizes.SIZE_4};

              &__name {
                ${fontSize(theme.fontSizes.FONT_16)};
                margin-bottom: ${theme.sizes.SIZE_24};
                margin-right: ${theme.sizes.SIZE_24};
                color: var(--SHADE_1_2);
                font-weight: 400;
                word-break: break-word;
              }

              &__close {
                ${position(
                  'absolute',
                  `top ${pxToRem('14px')} right ${pxToRem('14px')}`
                )};
                
                ${size(theme.sizes.SIZE_24)};


                color: var(--PRIMARY_1_1);
                min-height: unset;

                svg {
                  ${fontSize(theme.fontSizes.FONT_20)};
                }

                &:hover {
                  color: var(--PRIMARY_2_1);
                }
              }

              &__send-button {
                margin: auto;
              }
            }
          }
        }
      }

      .gm-ui-hover-effect,
      .gm-style-iw-ch {
        display: none !important;
      }
      
    }
  }

  .map-cluster-pin {
    font-weight: 500;
    transform: translateY(-1px);
  }

  .button{
    &--ghost.gray-ghost{
        color: var(--SHADE_1_2);

        &:hover {
          opacity: 0.9;
          color: var(--SHADE_1_3);
        }
    }
  }

  .info-window {
    display: none;
  }

  .people {
    // TODO: for now...
    background-color: var(--BACKGROUND_1_1) !important;
   
    &__edit-dropdown {
      .wcl-dropdown__content {
        padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_20};
      }
    }
  }

  .table-data-with-image {
    ${flex('row', 'flex-start', 'center')};


    img {
      ${size(theme.sizes.SIZE_32)};
      flex: 0 0 ${pxToRem(theme.sizes.SIZE_32)};
      margin-right: ${theme.sizes.SIZE_16};
      display: inline-block;
      vertical-align: middle;
    }
  }

  .overflow {
    overflow: auto;
  }

  .profile-img {
    background: var(--BACKGROUND_1_1);
    border-radius: 50%;
  }

  .wcl-chart-container {
    padding-top: ${theme.sizes.SIZE_24};
    margin-top: auto;
  }

  .input__container{
    color: var(--PRIMARY_1_1);
  }

  .wcl-modal {
    min-width: ${pxToRem(theme.sizes.SIZE_520)};

    &__close-btn{
      z-index: 1;
    }

    ${phablet(css`
      min-width: unset;
    `)};
  }

  .kinto-modal {
    &__actions {
      margin-top: unset;
      width: 100%;

      .button--delete-ghost {
        margin-left: auto;
      }
    }


    &__fields {
      ${marginChildren(`0 0 ${theme.sizes.SIZE_24} 0`)};
      width: 100%;

      &__fields-row {
        ${flexGap(theme.sizes.SIZE_8, 'row')};

        display: grid;
        align-items: flex-start;
        grid-template-columns: 1fr ${theme.sizes.SIZE_168};

        .input:not(.input:has([class*="input__label"])){
          .input__container{
            margin-top: ${theme.sizes.SIZE_20};
          }
        }
      }

      &__row-title{
        ${fontSize(theme.fontSizes.FONT_18, theme.sizes.SIZE_24)};

        font-weight: 400;
        margin : 0 0 ${theme.sizes.SIZE_20};
        color:var(--SECONDARY_1_1);
      }

      &.send-message-modal{
        padding: ${theme.sizes.SIZE_4} 0;
      }

      .textarea__container {
        width: 100%;
      }
    }
  }

  .kinto-filter-button {
    ${flex('row', 'center', 'center')};
    ${focusStyles};
    ${size('fit-content')};
    gap: ${theme.sizes.SIZE_8};
    background-color: var(--BACKGROUND_1_1);
    color: var(--SHADE_1_2);
    font-weight: 400;
    padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16};
    border-radius: ${theme.sizes.SIZE_32};
    border: none;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    &:hover {
      background-color: var(--BACKGROUND_2_1);
    }

    > svg {
      color: var(--PRIMARY_1_1);
    }

    &--filters-active {
      background-color: var(--PRIMARY_1_1);
      color: var(--BACKGROUND_1_1);

      &:hover {
        background-color: var(--PRIMARY_2_1);
      }

      > svg {
        color: var(--BACKGROUND_1_1);
      }
    }
  }

  .kinto-filters-modal {
    fieldset {
      padding: unset;
      border: unset;

      legend {
        font-size: ${theme.fontSizes.FONT_16};
        color: var(--PRIMARY_1_1);
        font-weight: 600;
        margin-bottom: ${theme.sizes.SIZE_16};
      }
    }

    &__form-actions {
      width: 100%;

      &__clear-all {
        /* TODO: consider form actions having another button... */
        margin-left: auto !important;
        grid-area: clear;
      }

      ${phablet(css`
        display: grid;
        gap: ${theme.sizes.SIZE_12};
        grid-template-columns: 1fr 1fr;
        margin-top: unset;
        grid-template-areas:
          'save save'
          'clear cancel';

        .button {
          margin: unset;
        }

        .cancel-btn {
          grid-area: cancel;
        }

        .button[type='submit'] {
          grid-column: span 2;
        }
      `)}
    }
  }

  .kinto-events-tab {
    &__events {
      &__infinite-scroll {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${theme.sizes.SIZE_10};
        padding: ${theme.sizes.SIZE_4};

        ${tablet(css`
          grid-template-columns: repeat(2, 1fr);
        `)};

        ${phablet(css`
          grid-template-columns: auto;
        `)};
      }
    }

    &__search {
      width: ${pxToRem(theme.sizes.SIZE_200)};
    }

    &__no-data {
      text-transform: capitalize;
      padding: ${theme.sizes.SIZE_24};
    }

    &__button { 
      margin: ${theme.sizes.SIZE_32} 0;
    }
  }

  .pac-container {
    z-index: 9999;
  }

  .wcl-overlay{
    &.image-preview{
      backdrop-filter: blur(5px);
      
      .wcl-modal {
        margin: auto 0;
        overflow: hidden;
        background-color:transparent;
        border-radius: ${theme.sizes.SIZE_24};
        
        &__main, &__header{
          padding: unset;
          margin: unset;
        }

        &__close-btn{
          z-index: 1;
          background: var(--BACKGROUND_1_1);
        }
      }
    }
  }

  .overlay {
    ${size('100%', '100%')};
    background-color: var(--BACKGROUND_1_2);
    ${position('absolute', '0')}

    &.position-fixed {
      ${position('fixed', '0')}
    }
  }

  .position-start {
    > * {
      justify-content: start;
      padding-top: ${theme.sizes.SIZE_48}
    }
  }

  .position-end {
    > * {
      justify-content: end;
      padding-bottom: ${theme.sizes.SIZE_48}
    }
  }

  .position-relative {
    position:relative;
  }

  .kinto-password-banner {
    background-color: var(--PRIMARY_1_7);
    width: fit-content;
    border-radius: ${theme.sizes.SIZE_8};
    padding: ${theme.sizes.SIZE_8};
  }

  #credential_picker_container[id]{
    top: ${theme.sizes.SIZE_10};
  }

  .pac-icon.pac-icon-marker {
    background: url('/assets/svg/location-marker.svg') no-repeat center;
    background-size: ${theme.sizes.SIZE_12};
  }

  .car-park-list {
    &__empty {
      ${marginChildren(`0 0 ${theme.sizes.SIZE_20} 0`)};

      &__title {
        font-weight: 600;
        ${fontSize(theme.fontSizes.FONT_18)};
      }
    }
  }

  .kinto-rewards {
    &__actions{
      ${flex('row', 'space-between', 'center')};
      
      width: 100%;

      ${phablet(css`
        ${flexGap(theme.sizes.SIZE_12, 'column')};

        flex-direction: column;
        align-items: flex-start;
        margin: 0 0 ${theme.sizes.SIZE_8};
      `)}
    }

    &__loader {
      margin: ${theme.sizes.SIZE_24} auto;
    }

    &__table{
      .wcl-table__data__action {
        .button {
          ${size(theme.sizes.SIZE_32)};
          min-height: unset;
        }
      }
    }
  }

  .reward-image {
    ${size(theme.sizes.SIZE_40)};

    object-fit: cover;
    border-radius: ${theme.sizes.SIZE_10};
  }

  .kinto-test-data {
    color: var(--ALERT_ERROR_1_1);
  }

  /* ////////////////////////////////////////////////////////// SCROLLBAR ////////////////////////////////////////////////////////// */
  ::-webkit-scrollbar {
    ${size(theme.sizes.SIZE_4)};
  }

  ::-webkit-scrollbar-track {
    background-color: var(--SUCCESS_1_1);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--SECONDARY_1_1);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--PRIMARY_1_1);
  }

  /* ////////////////////////////////////////////////////////// MESSAGES ////////////////////////////////////////////////////////// */
  .conversation-item {
    position: relative;

    &__user-image {
      ${size(theme.sizes.SIZE_40)};
      margin-right: ${theme.sizes.SIZE_24};
    }

    &__unread-count {
      ${fontSize(theme.fontSizes.FONT_14)};
      ${flex('row', 'center', 'center')};
      ${position(
        'absolute',
        `top -${theme.sizes.SIZE_10} right ${theme.sizes.SIZE_16}`
      )};
      border-radius: 50%;
      background-color: var(--ALERT_ERROR_1_1);
      min-width: ${pxToRem(theme.sizes.SIZE_24)};
      height: ${pxToRem(theme.sizes.SIZE_24)};
      color: var(--BACKGROUND_1_1);
    }
  }

  /* ////////////////////////////////////////////////////////// TABS ////////////////////////////////////////////////////////// */
  .kinto-tabs {
    display: grid;
    grid-template-columns: ${theme.sizes.SIZE_264} 1fr;
    height: 100%;

    @media (max-width: ${theme.breakAtMaxWidth}px) {
      grid-template-columns: 1fr;
    }

    &__title {
      ${fontSize(theme.sizes.SIZE_16, theme.sizes.SIZE_24)};

      font-weight: 600;
      text-transform: uppercase;
      color: var(--SHADE_1_2);
      margin: 0 0 ${theme.sizes.SIZE_32};
      padding: ${theme.sizes.SIZE_56} ${theme.sizes.SIZE_48} 0;

      @media (max-width: ${theme.breakAtMaxWidth}px) {
        padding: ${theme.sizes.SIZE_20} ${theme.sizes.SIZE_20} 0;
      }

      ${phablet(
        css`
          margin-bottom: ${theme.sizes.SIZE_16};
        `
      )};
    }

    &__navigation {
      &__community {
        padding: ${`${theme.sizes.SIZE_24} 0 ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_20}`};
      }
    }

    &__panel {
      ${flex('column', 'flex-start', 'stretch')};
      ${size('100%')};
      overflow: auto;
      background-color: var(--BACKGROUND_1_1);

      &__blue-bg {
        background-color: var(--BACKGROUND_2_1);
      }

      @media (max-width: ${theme.breakAtMaxWidth}px) {
        max-width: unset;
        box-shadow: unset;
        border: unset;
      }

      &__navigation {
        ${flex('row', 'flex-start', 'center')};

        width: 100%;
        flex: 0 0 ${pxToRem(theme.sizes.SIZE_46)};
        border-bottom: ${`${theme.sizes.SIZE_1} solid var(--SHADE_1_8)`};
        display: none;
        padding: ${theme.sizes.SIZE_8} 0;

        @media (max-width: ${theme.breakAtMaxWidth}px) {
          display: flex;
        }

        a.back {
          ${flex('row', 'initial', 'center')};
          ${fontSize(theme.fontSizes.FONT_16)};
          ${focusStyles};

          text-decoration: none;
          font-weight: 500;
          color: var(--SHADE_1_2);
          height: 100%;
          min-height: ${pxToRem(theme.sizes.SIZE_40)};
          margin-left: ${theme.sizes.SIZE_20};

          > span {
            margin-left: ${theme.sizes.SIZE_20};
            color: var(--PRIMARY_1_1);
          }
        }
      }
   
      @media (max-width: ${theme.breakAtMaxWidth}px) {
        border: none;
      }
    }
  }

  /* ////////////////////////////////////////////////////////// MODAL ////////////////////////////////////////////////////////// */
  .modal {
    .header {
      display: flex;
      flex-direction: column;
      color: var(--PRIMARY_1_1);
      text-align: center;
      padding-top: ${theme.sizes.SIZE_32};
      position: relative;

      &__icon {
        color: var(--ACCENT_1_1);

        svg {
          ${size(theme.sizes.SIZE_24)};
        }

        ${mobile(css`
          svg {
            ${size(theme.sizes.SIZE_20)};
          }
        `)}

        ${mobileLandscape(css`
          svg {
            ${size(theme.sizes.SIZE_20)};
          }
        `)}
      }

      button {
        position: absolute;
        align-self: flex-end;
        top: ${theme.sizes.SIZE_16};
        right: ${theme.sizes.SIZE_16};
      }

      &__title {
        ${fontSize(theme.fontSizes.FONT_34)};

        font-weight: 600;
        word-break: normal;
        overflow-wrap: anywhere;
        color: var(--PRIMARY_1_1);
        margin: 0 auto; 
        max-width: ${pxToRem(theme.sizes.SIZE_264)};

        ${tablet(css`
          ${fontSize(theme.fontSizes.FONT_30)};
          color: var(--PRIMARY_1_1);
          padding: 0 ${theme.sizes.SIZE_32};
        `)}

        ${mobile(css`
          ${fontSize(theme.fontSizes.FONT_22)};
          text-align: center;
        `)};

        ${mobileLandscape(css`
          ${fontSize(theme.fontSizes.FONT_24)};
          padding: 0 ${theme.sizes.SIZE_48};
        `)};
      }

      &__divider {
        ${size(theme.sizes.SIZE_264, theme.sizes.SIZE_1)};

        margin: ${theme.sizes.SIZE_24} auto;
        background-color: var(--SHADE_1_3);

        ${mobile(css`
          width: ${pxToRem(theme.sizes.SIZE_80)};
          margin: ${theme.sizes.SIZE_10} auto;
          background-color: var(--ACCENT_2_1);
        `)}

        ${mobileLandscape(css`
          width: ${pxToRem(theme.sizes.SIZE_80)};
          margin: ${theme.sizes.SIZE_10} auto;
        `)}
      }

      &--error {
        .header__title {
          color: var(--ALERT_ERROR_1_1);
        }

        .header__divider {
          border-color: var(--ALERT_ERROR_1_1);
        }
      }
    }

    .body {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
      overflow: auto;
      padding: ${`0 ${theme.sizes.SIZE_80} ${theme.sizes.SIZE_40}`};

      ${tablet(css`
        padding: 0 ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_40};
      `)}

      ${mobileLandscape(css`
        padding: ${`0 ${theme.sizes.SIZE_48} ${theme.sizes.SIZE_40}`};
      `)};
    }

    .footer {
      ${flex('row', 'center', 'center')};
      padding: ${`0 ${theme.sizes.SIZE_24} ${theme.sizes.SIZE_24}`};

      .delete-custom-input-modal {
        &__buttons {
          display: flex;
          flex-direction: row;
          margin-left: auto;

          ${mobile(css`
            margin: 0 auto;
            flex-direction: column;
          `)};

          > :first-child {
            margin-right: ${theme.sizes.SIZE_20};

            ${mobile(css`
              margin-right: unset;
              margin-bottom: ${theme.sizes.SIZE_20};
            `)};
          }

          > * {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  /* ////////////////////////////////////////////////////////// RIPPLE ////////////////////////////////////////////////////////// */
  span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 700ms linear;
    background-color: var(--SHADE_1_6);
  }

  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }

  .interstitial-page {
    &__template-header {
      padding-left: ${theme.sizes.SIZE_120};
      background-color: var(--SHADE_1_9);
      height: ${pxToRem(theme.sizes.SIZE_112)};

      .header__logo {
        margin-left: 0;
        width: ${pxToRem(theme.sizes.SIZE_120)};

        ${phablet(css`
          ${size(`${theme.sizes.SIZE_80}`, 'initial')};
        `)};
      }

      ${phablet(css`
        padding-left: ${theme.sizes.SIZE_80};
      `)};

      ${mobile(css`
        padding-left: ${theme.sizes.SIZE_40};
      `)};
    }

    &__template-main {
      background: linear-gradient(
        to right,
        var(--PRIMARY_1_1),
        var(--SECONDARY_1_1)
      );
      
      &.template__main{
        grid-template-columns: 60% 1fr;
      }

      ${laptop(css`
        width: 100% !important;

        &.template__main {
          grid-template-columns: 100% 1fr;
        }
      `)};
    }

    &__template-footer {
      display: none;
    }

    &__main {
      &__content {
        ${flex('column', 'center', 'flex-start')};
        padding: ${`${theme.sizes.SIZE_152} ${theme.sizes.SIZE_176}`};

        &__text {
          ${fontSize(theme.fontSizes.FONT_64, theme.fontSizes.FONT_64)};
          margin-bottom: ${theme.sizes.SIZE_80};
          color: var(--BACKGROUND_1_1);
          font-weight: 100;

          ${phablet(css`
            ${fontSize(theme.fontSizes.FONT_44, theme.fontSizes.FONT_44)};
            margin-bottom: ${theme.sizes.SIZE_64};
          `)};

          ${mobile(css`
            ${fontSize(theme.fontSizes.FONT_44, theme.sizes.SIZE_40)};
          `)};
        }

        &__table {
          display: flex;
          flex-direction: column;
          ${marginChildren(`0 0 ${theme.sizes.SIZE_20} 0`, true)};

          &__wrapper {
            ${flex('row', 'flex-start', 'baseline')};

            &__icon {
              margin-right: ${theme.sizes.SIZE_20};

              ${mobile(css`
                ${fontSize(theme.fontSizes.FONT_22)};
              `)};
            }

            &__link {
              color: var(--BACKGROUND_1_1);
              ${fontSize(theme.fontSizes.FONT_34)};
              text-decoration: none;
              word-break: normal;
overflow-wrap: anywhere;

              ${mobile(css`
                ${fontSize(theme.fontSizes.FONT_26)};
              `)};

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        ${phablet(css`
          padding: ${`${theme.sizes.SIZE_152} ${theme.sizes.SIZE_136}`};
        `)};

        ${mobile(css`
          padding: ${theme.sizes.SIZE_80} ${theme.sizes.SIZE_40};
        `)};
      }
    }
  }

  .activate-page {
    background-color: var(--BACKGROUND_2_1);
    height: 100%;
    
    .header {
      nav, button {
        display: none;
      }
    }
    .inner-footer {
      display: none;
    }

    &__main {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      &__info-box {
        grid-column: 2/3;
        margin-top: ${theme.sizes.SIZE_80};
        padding: ${theme.sizes.SIZE_64};
        background-color: var(--BACKGROUND_1_1);
        &__body {
          ${flex('row', 'flex-start', 'center')};
          &__icon {
            align-self: flex-start;
            margin-right: ${theme.sizes.SIZE_32};
            ${fontSize(theme.fontSizes.FONT_30)};
          }
          &__content {
            button {
              margin-top: ${theme.sizes.SIZE_64};
            }
          }
        }
      }

    ${mobile(css`
      grid-template-columns: 1fr;
      padding: 0 ${theme.sizes.SIZE_16};
      &__info-box {
        grid-column: 1/1;
        padding: ${`${theme.sizes.SIZE_64} ${theme.sizes.SIZE_20}`};
        &__body {
          flex-direction: column;
          text-align: center;
          &__icon {
            margin-right: 0;
            margin-bottom: ${theme.sizes.SIZE_20};
            align-self: center;
          }
        }
      }
    `)}
    }
  }

  .tab-view {
    .kinto-custom-fields-banner {
      width: fit-content;
      border-radius: ${theme.sizes.SIZE_8};
      background-color: var(--SHADE_1_9);
      color: var(--SHADE_1_1);
      padding: ${theme.sizes.SIZE_10} ${theme.sizes.SIZE_12};
      margin-top: ${theme.sizes.SIZE_8};
    }

    .expander {
      p {
        color: var(--SHADE_1_2);
      }

      .expander__trigger__title {
        ${flex('row', 'center', 'flex-end')};
        font-weight: 400;
      }

      .expander__icon {
        transform: none;
        color: var(--ACCENT_1_1);

        &--open {
          transform: none;
        }
      }
    }

    a {
      text-decoration: none;
    }

    &__row{
      &__edit{
        ${flex('row', 'center', 'center')};
        ${flexGap(theme.sizes.SIZE_12, 'row')};
        ${size('fit-content', theme.sizes.SIZE_48)};
        ${focusStyles};

        color: var(--PRIMARY_1_1);
        margin-top: ${theme.sizes.SIZE_32};
        border-radius: ${theme.sizes.SIZE_8};

        &:hover{
          color: var(--PRIMARY_2_1);
        }

      }
    }
  }

  #main {
    position: relative;
  }

  .kinto-tabs__panel__gamification {
    ${flex('column')};
    height: 100%;
  }

  /* iOS fix for zoom fields */
  @supports (-webkit-touch-callout: none) {
    input, select, textarea {
      ${mobile(css`
        ${fontSize(theme.fontSizes.FONT_16)};
      `)}
    }
  }

  /* ////////////////////////////////////////////////////////// SETTINGS ////////////////////////////////////////////////////////// */
  @keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeOutUp {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  }
`;
