import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { uniqueId } from 'lodash';
import {
  Breadcrumbs,
  Switch,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import { Form, FormRef } from '@faxi/web-form';
import { useCallbackAsync, useHeadTitle } from 'hooks';
import { apiGamification } from 'modules';
import dayjs from 'dayjs';
import config from 'config';
import PointsForm from './components/PointsForm';
import { UserContext } from 'store';
import { Depot, LeaderBoardFilterType, PointsDepot, DepotFilter } from 'models';
import { parseNumberObjectToStringObject } from './components/PointsForm/utils';
import { snackBarSuccessMessage } from 'utils';
import usePointsPromptProps from './usePointsPromptProps';

import * as Styled from './Points.styles';

declare global {
  interface Window {
    skipSubmit: boolean;
  }
}

export const POINTS_FORM_FIELDS = {
  DRIVING_POINTS: 'driving_points',
  WALKING_POINTS: 'walking_points',
  CYCLING_POINTS: 'cycling_points',
  PASSENGER_POINTS: 'passenging_points',
  DISTANCE_COEFFICIENT: 'distance_coefficient',
} as const;

export const POINT_FIELDS = [
  {
    name: POINTS_FORM_FIELDS.DRIVING_POINTS,
    placeholder: 'A_driver',
    switchLabel: 'driver_points_enabled',
  },
  {
    name: POINTS_FORM_FIELDS.PASSENGER_POINTS,
    placeholder: 'A_passenger',
    switchLabel: 'passenger_points_enabled',
  },
  {
    name: POINTS_FORM_FIELDS.WALKING_POINTS,
    placeholder: 'A_walker',
    switchLabel: 'walker_points_enabled',
  },
  {
    name: POINTS_FORM_FIELDS.CYCLING_POINTS,
    placeholder: 'A_cyclist',
    switchLabel: 'cyclist_points_enabled',
  },
];

export const DEFAULT_POINTS = {
  driving_points: '50',
  walking_points: '50',
  cycling_points: '50',
  passenging_points: '50',
  distance_coefficient: '10',
  driving_points_enabled: true,
  walking_points_enabled: true,
  cycling_points_enabled: true,
  passenging_points_enabled: true,
};

const mapFiltersData = (filters: LeaderBoardFilterType[]): DepotFilter[] => {
  return filters?.map(
    (filter) =>
      ({
        type: 'point-filter',
        data: { name: filter.name, from: filter.startDate, to: filter.endDate },
      } as DepotFilter)
  );
};

const getDisabledPoints = (disabled?: string[]) => {
  if (!disabled) return {};

  return {
    cycling_points_enabled: !disabled?.find((key) => key === 'cycling'),
    driving_points_enabled: !disabled?.find((key) => key === 'driving'),
    walking_points_enabled: !disabled?.find((key) => key === 'walking'),
    passenging_points_enabled: !disabled?.find((key) => key === 'passenging'),
  } as Partial<PointsDepot>;
};

const Points: FC = () => {
  const { t } = useTranslation();
  const { organisationId } = useParams() as { organisationId: string };

  const { communityId } = useContext(UserContext);

  const depotId = useRef<string>();
  const outsideSubmit = useRef<boolean>(false);

  const [form, formRef] = useCallbackRef<FormRef>();

  const [initialData, setInitialData] = useState<{
    data: Partial<PointsDepot>;
  }>();

  const [enablePoints, setEnablePoints] = useState<boolean>();

  const { prompt } = useUtilities();

  const [deactivatePromptProps, activatePromptProps] = usePointsPromptProps();

  useHeadTitle(
    `${t('group_settings')} - ${t('global-gamification')} - ${t(
      'global-points'
    )}`
  );

  const initialValue = useMemo(
    () =>
      ({
        ...DEFAULT_POINTS,
        ...initialData?.data,
      } as PointsDepot),
    [initialData]
  );

  const breadcrumbsLinks = useMemo(
    () => [
      {
        id: 'gamification-link',
        text: t('global-gamification'),
        href: `/community/${organisationId}/admin/gamification`,
      },
      {
        id: 'points-link',
        text: t('global-points'),
        href: `/community/${organisationId}/admin/gamification/points-system`,
      },
    ],
    [t, organisationId]
  );

  const [loadPointsDepot] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.kinto-page',
    callback: async () => {
      const {
        data: {
          data: { depots },
        },
      } = await apiGamification.getOrganisationDepots(+organisationId, 'point');

      const depot = depots[depots.length - 1];
      const { disabled, ...restDepotData } = depot?.data ?? {};

      const filters =
        depot?.filters?.map(
          (filter) =>
            ({
              id: uniqueId(),
              name: filter.data.name,
              startDate: filter.data.from,
              endDate: filter.data.to,
            } as LeaderBoardFilterType)
        ) || [];

      setInitialData({
        data: {
          filters,
          ...getDisabledPoints(disabled),
          ...parseNumberObjectToStringObject(restDepotData),
        },
      });

      setEnablePoints(depot?.state === 'active');

      depotId.current = depot ? `${depot?.id}` : undefined;
    },
    deps: [communityId],
  });

  const submitPointsForm = useCallback(() => {
    outsideSubmit.current = true;

    const submit = document.querySelector('#point-form-submit');
    (submit as HTMLInputElement).click();
  }, []);

  const [handleOnSubmit] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.kinto-points-form',
    deps: [enablePoints],
    callback: async ({ ...values }: PointsDepot) => {
      const isEditing = !!depotId.current;

      const {
        cycling_points_enabled,
        driving_points_enabled,
        walking_points_enabled,
        passenging_points_enabled,
        ...numberValues
      } = values as Omit<PointsDepot, 'points_enabled'>;

      const enabledModes = {
        cycling_points_enabled,
        driving_points_enabled,
        walking_points_enabled,
        passenging_points_enabled,
      } as Pick<
        PointsDepot,
        | 'cycling_points_enabled'
        | 'driving_points_enabled'
        | 'passenging_points_enabled'
        | 'walking_points_enabled'
      >;

      const disabled = Object.entries(enabledModes).reduce(
        (currentValue, [key, value]) => {
          if (!value) return [...currentValue, key.split('_')[0]];
          else return currentValue;
        },
        [] as Array<string>
      );

      const filters = mapFiltersData(values?.filters);

      const pointDepot = {
        type: 'point',
        description: '',
        start_date: dayjs().format(config.apiDateFormatExtended),
        end_date: '',
        data: {
          disabled,
          organisation_id: +organisationId,
          ...(numberValues as Omit<PointsDepot, 'points_enabled'>),
        },
        filters,
        state: outsideSubmit.current === enablePoints ? 'inactive' : 'active',
        actions: [],
        triggers: [
          {
            name: 'journey-verified-trigger',
            data: [],
          },
        ],
      } as unknown as Depot;

      if (!isEditing) {
        const {
          data: { data: newDepot },
        } = await apiGamification.createDepot(pointDepot);

        depotId.current = `${newDepot.id}`;
      } else {
        await apiGamification.updateDepot(depotId.current!, pointDepot);
      }

      snackBarSuccessMessage(
        t(
          `gamification-points_toast_${
            outsideSubmit.current
              ? enablePoints
                ? 'points_deactivated'
                : 'points_activated'
              : 'updated'
          }`
        )
      );

      setInitialData({
        data: {
          ...numberValues,
          ...getDisabledPoints(disabled),
        },
      });

      outsideSubmit.current = false;
    },
  });

  useEffect(() => {
    loadPointsDepot();
  }, [loadPointsDepot, communityId]);

  const handleSwitch = useCallback(async () => {
    const proceed = await prompt(
      enablePoints ? deactivatePromptProps : activatePromptProps
    );

    if (!proceed) return;
    setEnablePoints((old) => !old);

    try {
      submitPointsForm();
    } catch (e) {
      console.error(e);
    }
  }, [
    prompt,
    enablePoints,
    deactivatePromptProps,
    activatePromptProps,
    submitPointsForm,
  ]);

  return (
    <Styled.PointsPage>
      <Breadcrumbs
        tabIndex={0}
        aria-label="breadcrumbs"
        crumbs={breadcrumbsLinks}
      />

      <div className="kinto-points-form">
        <div className="kinto-points-form__header">
          <Switch
            name="points_enabled"
            className="kinto-points-form__header__switch"
            value={enablePoints}
            onChange={async (ev) => {
              ev.preventDefault();
              handleSwitch();
            }}
            label={t(enablePoints ? 'enabled' : 'disabled')}
            disabled={!form?.syncFormValid}
          />

          <div className="kinto-points-form__header__container">
            <h2 className="kinto-points-form__header__container__title">
              {t('gamification-points_title_journey_verification_points')}
            </h2>

            <div className="kinto-points-form__header__container__description">
              {t('gamification-points_subtitle_define_points')}
            </div>
          </div>
        </div>

        <Form
          ref={formRef}
          id="points-form"
          initialData={initialValue}
          onSubmit={async (v) => {
            if (window.skipSubmit) {
              window.skipSubmit = false;
              return;
            }

            handleOnSubmit(v);
          }}
        >
          <PointsForm
            formRef={form}
            data={initialValue}
            pointsAreEnabled={enablePoints}
          />

          <input
            hidden
            type="submit"
            id="point-form-submit"
            title={t('groupreg_submit')}
            aria-label={t('groupreg_submit')}
          />
        </Form>
      </div>
    </Styled.PointsPage>
  );
};

export default Points;
