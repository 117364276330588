import { FC, useContext, useRef } from 'react';

import { apiAuth } from 'modules';
import { AuthContext } from 'store';
import { useCallbackAsync } from 'hooks';
import useGoogleLogin, {
  CredentialResponse,
} from 'providers/Google/hooks/useGoogleLogin';
import SocialButton from 'components/_atoms/SocialButton';

const Google: FC = () => {
  const { applyCredentials } = useContext(AuthContext);
  const initialOneTap = useRef<boolean>(true);

  const [socialLoginHandler] = useCallbackAsync({
    showSpinner: true,
    catchAsyncErrors: false,
    callback: async (response: CredentialResponse) => {
      const { credential } = response;
      if (!credential) return;
      initialOneTap.current = false;

      const authData = await apiAuth.socialLogin('google', credential);
      applyCredentials(authData);
    },
  });

  const { googleLogin } = useGoogleLogin({
    onSuccess: socialLoginHandler,
    useOneTap: initialOneTap.current,
  });

  return <SocialButton provider="google" onClick={googleLogin} />;
};

export default Google;
