import { useCallback, useContext, useRef } from 'react';
import { useEffectOnceWhen, useLatestRef } from '@faxi/web-component-library';
import GoogleContext from '../Google.context';

export type CredentialResponse = {
  /** This field is the returned ID token */
  credential?: string;
  /** This field sets how the credential is selected */
  select_by?:
    | 'auto'
    | 'user'
    | 'user_1tap'
    | 'user_2tap'
    | 'btn'
    | 'btn_confirm'
    | 'btn_add_session'
    | 'btn_confirm_add_session';
  clientId?: string;
};

export type GoogleCredentialResponse = CredentialResponse & {
  client_id?: string;
};

export type GoogleLoginProps = {
  useOneTap?: boolean;
  onError?: () => void;
  onSuccess?: (response: GoogleCredentialResponse) => void;
};

export default function useGoogleLogin(props: GoogleLoginProps) {
  const { useOneTap = false, onSuccess, onError } = props;

  const googleContext = useContext(GoogleContext);
  const { clientId, scriptLoaded } = googleContext;

  const googleBtnRef = useRef<HTMLElement>();

  const onErrorRef = useLatestRef(onError);
  const onSuccessRef = useLatestRef(onSuccess);

  if (!googleContext)
    throw new Error(
      'Google OAuth components must be used within GoogleProvider'
    );

  const createFakeGoogleBtn = useCallback(() => {
    const googleLoginWrapper = document.createElement('div');
    googleLoginWrapper.style.display = 'none';
    document.body.appendChild(googleLoginWrapper);

    window?.google?.accounts?.id?.renderButton(googleLoginWrapper, {});

    googleBtnRef.current = googleLoginWrapper.querySelector(
      'div[role="button"]'
    ) as HTMLElement;
  }, []);

  const googleLogin = useCallback(
    () => ({
      click: googleBtnRef.current?.click(),
    }),
    []
  );

  useEffectOnceWhen(() => {
    window?.google?.accounts?.id?.initialize({
      client_id: clientId,
      callback: (credentialResponse: GoogleCredentialResponse) => {
        if (!credentialResponse?.credential) {
          return onErrorRef.current?.();
        }

        onSuccessRef.current?.(credentialResponse);
      },
      ...props,
    });

    createFakeGoogleBtn();

    if (useOneTap) {
      window?.google?.accounts?.id?.prompt();
    }

    return () => {
      if (useOneTap) window?.google?.accounts?.id?.cancel();
    };
  }, scriptLoaded);

  return { googleLogin };
}
