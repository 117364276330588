import { useMemo, useCallback, useContext, FC } from 'react';
import {
  Form,
  validators,
  validationRegexes,
  FormField,
  DataState,
  FormRef,
} from '@faxi/web-form';
import { useTranslation } from 'react-i18next';
import {
  Heading,
  getColor,
  useCallbackRef,
  useFormButtons,
  useUtilities,
} from '@faxi/web-component-library';

import { UserContext } from 'store';
import { useCallbackAsync } from 'hooks';
import { snackBarSuccessMessage } from 'utils';
import specific from 'validation/validators/specific';

import {
  Icon,
  InputField,
  FormActions,
  ImageDropzoneField,
  PageLayout,
  Containers,
} from 'components';

const UpdateProfileForm: FC = () => {
  const { t } = useTranslation();

  const [FormButtons, setLoading] = useFormButtons({
    submitLabel: t('Save'),
    cancelLabel: t('cancel'),
    loadingOverlaySelector: '.kinto-page',
  });

  const { user, updateUser } = useContext(UserContext);

  const { showSnackBar } = useUtilities();

  const [form, formRef] = useCallbackRef<FormRef>();

  const initialData = useMemo(
    () => ({
      profile: user?.image_url,
      firstName: user?.firstname,
      lastName: user?.name,
      mobileNumber: user?.phone,
    }),
    [user]
  );

  const validations = useMemo(
    () => ({
      firstName: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('register_fname_hint'),
          })
        ),
        validators.general.regex(
          validationRegexes.name,
          t('validation-field_name_first_last', {
            fieldname: t('register_fname_hint').toLowerCase(),
          })
        ),
        validators.general.maxLength(
          20,
          t('validation-field_validation_max_length', {
            fieldname: t('register_fname_hint').toLowerCase(),
            number: '20',
          })
        ),
      ],
      lastName: [
        validators.general.required(
          t('validation-field_is_required', {
            fieldname: t('register_lname_hint'),
          })
        ),
        validators.general.regex(
          validationRegexes.name,
          t('validation-field_name_first_last', {
            fieldname: t('register_lname_hint').toLowerCase(),
          })
        ),
        validators.general.maxLength(
          50,
          t('validation-field_validation_max_length', {
            fieldname: t('register_lname_hint').toLowerCase(),
            number: '50',
          })
        ),
      ],
      mobileNumber: [
        validators.phone.regex(
          specific.PHONE_NUMBER,
          t('validation-field_phone_number')
        ),
      ],
    }),
    [t]
  );

  const handleSubmit = useCallback(
    async (data: DataState) => {
      if (!user) {
        return;
      }

      const transformedData = {
        name: data.lastName,
        firstname: data.firstName,
        phone: data.mobileNumber,
      };

      try {
        setLoading(true);
        await updateUser(user.id, transformedData, 'PUT');
        showSnackBar({
          actionButtonText: t('dismiss'),
          text: t('personal_details_successfully_updated'),
          variant: 'success',
        });
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [user, setLoading, updateUser, showSnackBar, t]
  );

  const [uploadUserProfileImage] = useCallbackAsync({
    showSpinner: true,
    callback: async (image: File) => {
      if (!user) return;

      await updateUser(user.id, {
        file: 'PROFILEPIC',
        image,
      });

      const imageURL = URL.createObjectURL(image);

      snackBarSuccessMessage(t('my_account_notification_image_uploaded'));

      return imageURL;
    },
  });

  const [deleteUserProfileImage] = useCallbackAsync({
    callback: async () => {
      if (!user) return;

      await updateUser(
        user.id,
        {
          file: 'PROFILEPIC',
        },
        'DELETE'
      );
    },
  });

  return (
    <PageLayout className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t('account-personal_details_update_profile')}
      </Heading>
      <Containers.SettingsForm>
        <Form
          id="personal_info_form"
          initialData={initialData}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <fieldset className="form__fields">
            <legend data-hidden hidden>
              {t('settings_personal_title')}
            </legend>
            <FormField
              name="profile"
              circularCrop
              component={ImageDropzoneField}
              title={t('my_account-title_profile_image')}
              placeholder={<Icon name="user-circle" />}
              uploadRequest={uploadUserProfileImage}
              deleteRequest={deleteUserProfileImage}
              accept={{
                'image/png': ['.png'],
                'image/jpg': ['.jpg'],
                'image/jpeg': ['.jpeg'],
              }}
            />
            <FormField
              id="person_first_name"
              name="firstName"
              autoComplete="on"
              component={InputField}
              placeholder={t('register_fname_hint')}
              validate={validations.firstName}
              required
              requiredLabel={t('global-input_field_required_label')}
            />
            <FormField
              id="person_last_name"
              name="lastName"
              autoComplete="on"
              component={InputField}
              placeholder={t('register_lname_hint')}
              validate={validations.lastName}
              required
              requiredLabel={t('global-input_field_required_label')}
            />

            <FormField
              id="person_private_phone"
              name="mobileNumber"
              component={InputField}
              autoComplete="on"
              placeholder={t('dashboard_details_phone_number')}
              validate={validations.mobileNumber}
            />
          </fieldset>

          <FormActions className="form__actions">
            <FormButtons.Submit
              id="submit_personal_info"
              disabled={
                !form?.isFormChanged(['profile']) || !form?.syncFormValid
              }
            />
          </FormActions>
        </Form>
      </Containers.SettingsForm>
    </PageLayout>
  );
};

export default UpdateProfileForm;
