import { FC, useContext, useEffect, useMemo } from 'react';
import {
  Heading,
  getColor,
  useElementClass,
} from '@faxi/web-component-library';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';

import { UserContext } from 'store';
import { PageLayout } from 'components/_layouts';
import appUri from './appUri';

type RoutesRenderer = {
  title?: string;
};

const RoutesRenderer: FC<RoutesRenderer> = (props) => {
  const { title } = props;

  const { communityId, community, userReady } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();

  // TODO: reorganize this when time allows
  const campaignFormIsRendered = useMemo(
    () =>
      location.pathname.split('/').at(-1) === 'create-new' ||
      location.pathname.split('/').at(-2) === 'edit',
    [location]
  );

  useElementClass('.kinto-page', 'gamification--blue', campaignFormIsRendered);

  const premiumFeatureAvailable = useMemo(
    () =>
      !!community?.premium_features?.find(
        ({ name }) => name === 'gamification_feature'
      ),
    [community]
  );

  // TODO: this is used on multiple places, don't infer that is only gamification, remove title dep from here when resolved
  useEffect(() => {
    if (!premiumFeatureAvailable && communityId && userReady && title) {
      navigate(appUri.COMMUNITY_DASHBOARD);
    }
  }, [communityId, premiumFeatureAvailable, title, userReady, navigate]);

  return !title ? null : (
    <PageLayout className={classNames('kinto-page', 'gamification')}>
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__heading"
      >
        {t(title)}
      </Heading>

      <Outlet />
    </PageLayout>
  );
};

export default RoutesRenderer;
